import { ApiServiceReturnType } from '../types/Api';
import { Category, Day } from '../types/Day';
import { RipenessIndexDTO } from '../types/RipenessIndex';
import { baseURL, createHeader } from './utils';

export const getLastTenDays = async ({ queryString }: { queryString?: string }): Promise<ApiServiceReturnType<Day[]>> => {
  const headers = await createHeader();
  const requestUrl = queryString ? `${baseURL}/getLastTenDays?${queryString}` : `${baseURL}/getLastTenDays`

  try {
    const res = await fetch(requestUrl, { headers });
    const JSONResponse = await res.json();

    if (res.status >= 400) {
      throw new Error(JSONResponse.error);
    }
    return { ...JSONResponse, status: res.status };
  } catch (e: any) {
    return e;
  }
};

export const getAllDays = async ({ queryString }: { queryString?: string }): Promise<ApiServiceReturnType<Day[]>> => {
  const headers = await createHeader();

  const requestUrl = queryString ? `${baseURL}/getAllDays?${queryString}` : `${baseURL}/getAllDays`
  try {
    const res = await fetch(requestUrl, { headers });
    const JSONResponse = await res.json();

    if (res.status >= 400) {
      throw new Error(JSONResponse.error);
    }
    return { ...JSONResponse, status: res.status };
  } catch (e: any) {
    return e;
  }
};

export const getCategories = async ({ queryString }: { queryString?: string }): Promise<ApiServiceReturnType<Category[]>> => {
  const headers = await createHeader();

  const requestUrl = queryString ? `${baseURL}/getCategories?${queryString}` : `${baseURL}/getCategories`
  try {
    const res = await fetch(requestUrl, { headers });
    const JSONResponse = await res.json();

    if (res.status >= 400) {
      throw new Error(JSONResponse.error);
    }
    return { ...JSONResponse, status: res.status };
  } catch (e: any) {
    return e;
  }
};


export const getLatestRIData = async (): Promise<ApiServiceReturnType<[RipenessIndexDTO, RipenessIndexDTO]>> => {
  const headers = await createHeader();

  const requestUrl = `${baseURL}/getLatestRI`
  try {
    const res = await fetch(requestUrl, { headers });
    const JSONResponse = await res.json();

    if (res.status >= 400) {
      throw new Error(JSONResponse.error);
    }
    return { ...JSONResponse, status: res.status };
  } catch (e: any) {
    return e;
  }
}
