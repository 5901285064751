import { Day } from "../../../types/Day";
import { DEvent } from "../../../types/DEvent";
import { RawDateString } from "../../../types/utilTypes";
import { GraphOptions, getColorFromString, getStartOfWeekFromDay } from "../../../utils/functions";

export const convertEventsToChartLabels = (events: DEvent[], options?: GraphOptions) => {
  return events.map((event, i, a) => ({
    type: 'line',
    scaleID: 'x',
    value: options?.interval ? event[options.interval] : event.dayString,
    borderColor: 'red',
    borderWidth: 2,
    label: {
      display: true,
      content: event.title,
      enabled: true,
      position: `${(i / a.length) * 100}%`
    },
  }));
}

export const convertDaysToChartJSData = (daysArray: Day[], options?: GraphOptions) => {
  const interval = options?.interval ? options?.interval : 'dayString';
  const sortedDaysArray = [...daysArray].sort((a, b) => a[interval].localeCompare(b[interval])
  );
  // create an array of unique dates from the sorted days.
  //  uniqueDates will be used as the labels on the x-axis of the chart.
  let uniqueDates: string[] = [];
  if (options?.startPeriod && options.endPeriod) {
    const startDate = new Date(options.startPeriod);
    const currentDate = interval === 'dayString' ? startDate : getStartOfWeekFromDay(options.startPeriod as RawDateString);
    const jump = interval === 'dayString' ? 1 : 7;
    const endDate = new Date(options.endPeriod);
    while (currentDate <= endDate) {
      uniqueDates.push(currentDate.toISOString().split('T')[0]);
      currentDate.setDate(currentDate.getDate() + jump);
    }
  } else {
    uniqueDates = Array.from(
      new Set(sortedDaysArray.map((day) => day[interval]))
    );
  }

  // creates an object that tracks the count of each category for each language on each date.
  const categoryLangCounts: {
    [category: string]: { [lang: string]: { [date: string]: number; }; };
  } = {};

  sortedDaysArray.forEach((day) => {
    const dateStr: RawDateString = day[interval];
    const categoryTitle = day.category.title;
    const lang = day.lang;

    // If the category doesn't exist in categoryLangCounts,
    // initializes an empty object for that category.
    // e.g. categoryLangCounts['physical'] = {}
    if (!categoryLangCounts[categoryTitle]) {
      categoryLangCounts[categoryTitle] = {};
    }

    if (!categoryLangCounts[categoryTitle][lang]) {
      categoryLangCounts[categoryTitle][lang] = {};
    }

    if (!categoryLangCounts[categoryTitle][lang][dateStr]) {
      categoryLangCounts[categoryTitle][lang][dateStr] = 0;
    }

    categoryLangCounts[categoryTitle][lang][dateStr] += day.count;
  });

  // for each category e.g. 'World legitimacy -> Israel',
  const datasets = Object.keys(categoryLangCounts).flatMap((categoryTitle) => {
    //go through each lang
    return Object.keys(categoryLangCounts[categoryTitle]).map((lang) => {
      const data = uniqueDates.map((date) => {
        return categoryLangCounts[categoryTitle][lang][date] !== undefined
          ? categoryLangCounts[categoryTitle][lang][date]
          : 0;
      });

      return {
        label: `${categoryTitle} (${lang})`,
        // data: (number | null)[] representing point on line
        data,
        fill: false,
        backgroundColor: getColorFromString(`${categoryTitle}-${lang}`), // Ensure background color matches for filled legend circle
        borderColor: getColorFromString(`${categoryTitle}-${lang}`), // Use the hashing function for consistent color
      };
    });
  });

  return {
    labels: uniqueDates,
    datasets,
  };
};

export const customLegendPlugin = {
  id: 'customLegend',
  afterDraw(chart: { data?: any; ctx?: any; legend?: any; }) {
    const { ctx, legend } = chart;

    // Check if legend and legend items exist before drawing custom legend circles
    if (legend && legend.legendItems && legend.legendItems.length) {

      legend.legendItems.forEach((label: any, index: string | number) => {
        const dataset = chart.data.datasets[index];
        const fillColor = dataset.backgroundColor || dataset.borderColor;

        // Ensure that the fillColor and legend hitboxes are defined
        if (fillColor && legend.legendHitBoxes[index]) {
          const legendX = legend.legendHitBoxes[index].left + 7;
          const legendY = legend.legendHitBoxes[index].top + 7;

          // Draw filled circle
          ctx.beginPath();
          ctx.arc(legendX, legendY, 5, 0, 2 * Math.PI);
          ctx.fillStyle = fillColor;
          ctx.fill();
          ctx.strokeStyle = fillColor;
          ctx.stroke();
          ctx.closePath();
        }
      });
    }
  },
};

