import { useEffect } from 'react';
import { loadUsers, selectUser, selectUsers } from '../../store/userSlice';
import { useAppDispatch, useAppSelector } from '../../utils/hooks';
import {
  loadLatestRIData,
  loadMHSData,
  loadWOData,
  selectMHSData,
  selectWOData,
} from '../../store/dataSlice';
import LineGraph from '../dataDisplay/Graph/LineGraph';
import CustomDashboard from '../dataDisplay/CustomDashboard';
import RipenessIndex from '../dataDisplay/RipenessIndex/RipenessIndex';
import Events from '../Events/EventsSection';
import { loadDEvents } from '../../store/dEventsSlice';
import { loadUEvents } from '../../store/uEventsSlice';
import UEvents from '../UEvents/UEvents';
import { getFormattedLastTenDaysString } from '../../utils/functions';
import UserList from '../UserList/UserList';

export default function Home() {
  const user = useAppSelector(selectUser);
  const users = useAppSelector(selectUsers);

  const dispatch = useAppDispatch();
  const MHSdays = useAppSelector(selectMHSData);
  const WODays = useAppSelector(selectWOData);
  useEffect(() => {
    if (!user) return;
    dispatch(loadUEvents());
    dispatch(loadMHSData());
    if (user && user.access.admin){
      dispatch(loadUsers())
    }
    dispatch(loadWOData());
    dispatch(loadDEvents());
    dispatch(loadLatestRIData());
  }, [dispatch, user]);

  return (
    <>
      <div
        style={{
          margin: '50px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
        }}
      >
        <RipenessIndex />
      </div>
      <span
        style={{ display: 'flex', justifyContent: 'space-around', margin: 20 }}
      >
        {!!MHSdays.length && (
          <LineGraph
            subtitle={getFormattedLastTenDaysString()}
            width={'45%'}
            title='Mutually Hurting Stalemate'
            height={'80vh'}
            data={MHSdays}
          />
        )}
        {!!WODays.length && (
          <LineGraph
            subtitle={getFormattedLastTenDaysString()}
            width={'45%'}
            height={'80vh'}
            title='Way Out'
            data={WODays}
          />
        )}
      </span>
      {user?.access.designPartner && <CustomDashboard />}
      {user?.access.admin && <Events />}
      {user?.access.admin && <UserList users={users} /> }
      {user?.access.admin && <UEvents />}
    </>
  );
}
