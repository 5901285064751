export interface RipenessIndexDTO {
  date: Date,
  lang: 'HE' | 'AR'
  confidentInWinningPercent: number;
  skepticPercent: number;
  forCompromisePercent: number;
  againstCompromisePercent: number;
  sufferingPercent: number;
}

export enum Segments {
  mhs = 'MHS',
  wo = 'WO',
  confident = 'Confident in Winning',
  Willingness = 'Willingness to compromise',
  firmness = 'Firmness not to compromise',
  hurt = 'Hurt',
  impossibility = 'Impossibility of winning',
  ri = 'Ripeness Index'
}

export interface RipenessIndexDTO {
  date: Date;
  lang: 'HE' | 'AR';
  confidentInWinningPercent: number;
  skepticPercent: number;
  forCompromisePercent: number;
  againstCompromisePercent: number;
  sufferingPercent: number;
}

