import { leftSideMHSSegments, minimumDate, RIColor, RIColorToHex, rightSideWOSegments, RIHexCode, segmentThresholds } from "../constants";
import { SegmentsObject } from "../store/dataSlice";
import { Day } from "../types/Day";
import { RipenessIndexDTO, Segments } from "../types/RipenessIndex";
import { RawDateString } from "../types/utilTypes";

export const getRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

const HeCategoryToColor: Record<string, string> = {
  "Diplomatic": "AAAEC6",
  "Security": "DBB735",
  "Domestic Approval": "3544DB",
  "Economic": "DB1481",
  "Total Suffering": "E00202",
  "Confident in Winning": "8FDE26",
  "Skeptical of Winning": "DE8026",
  "Against Compromise": "BE26DE",
  "Willingness to Compromise": "26B6DE",
};

const ArCategoryToColor: Record<string, string> = {
  "Diplomatic": "4C4F62",
  "Security": "A88607",
  "Domestic Approval": "161D5C",
  "Economic": "8F0D54",
  "Total Suffering": "7D0505",
  "Confident in Winning": "476F13",
  "Skeptical of Winning": "6F4113",
  "Against Compromise": "641474",
  "Willingness to Compromise": "135B6F",
};

export const getConstantColorByHash = (str: string) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  const color = (hash & 0x00FFFFFF).toString(16).toUpperCase();
  return '#' + '00000'.substring(0, 6 - color.length) + color;
}

export const getColorFromString = (str: string): string => {
  const lang = str.slice(-2).toLowerCase();
  const title = str.slice(0, -3);
  if (lang === 'he' && HeCategoryToColor[title]) {
    return `#${HeCategoryToColor[title]}`
  } else if (lang === 'ar' && ArCategoryToColor[title]) {
    return `#${ArCategoryToColor[title]}`;
  } else {
    return getConstantColorByHash(str);
  }
};

export const convertObjectToQueryString = (obj: { [x: string]: any }) => {
  var str = [];
  for (var p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return str.join("&");
}

export const filterByDateLangAndCat = (days: Day[], options: GraphOptions): Day[] => {
  return days.filter(day => {
    if (options?.parentCategories && !options.parentCategories.includes(day.category.parentCategory)) {
      return false
    }
    let langMatches = true;
    if (options?.lang) {
      langMatches = options?.lang.includes(day.lang);
    }
    const dayDate = new Date(day.dayAsDate);
    const startDate = options?.startPeriod ? new Date(options.startPeriod) : null;
    const endDate = options?.endPeriod ? new Date(options.endPeriod) : null;
    const startMatches = startDate ? dayDate >= startDate : true;
    const endMatches = endDate ? dayDate <= endDate : true;

    return langMatches && startMatches && endMatches;
  });
};

export const getColorFromRI = (segment: Segments, count: number): RIHexCode => {
  const segmentThreshold = segmentThresholds[segment];

  for (let colorRange of Object.keys(segmentThreshold) as RIColor[]) {
    const range = segmentThreshold[colorRange];

    if (count >= range.lower && count <= range.higher) {
      return RIColorToHex[colorRange];
    }
  }

  return RIHexCode['yellow']; // Default color if no match
};

export type Interval = 'weekString' | 'dayString';

export interface GraphOptions {
  interval?: Interval;
  lang?: ('HE' | 'AR')[];
  startPeriod?: string;
  endPeriod?: string;
  parentCategories?: string[];
}

export const getStartOfWeekFromDay = (day: RawDateString) => {
  const dayDate = new Date(day)
  const dayOfWeek = dayDate.getDay();
  const diff = dayDate.getDate() - dayOfWeek;

  const startOfWeek = new Date(day);
  startOfWeek.setDate(diff);
  return startOfWeek;
}


export interface SubmitError {
  disabled: boolean;
  reason: string;
}

export const isSubmitDisabled = (
  startPeriod: string,
  endPeriod: string
): SubmitError => {
  const startDate = new Date(startPeriod);
  const endDate = new Date(endPeriod);
  const today = new Date();

  if (startDate > endDate) {
    return { disabled: true, reason: 'Start date cannot be after end date.' };
  }

  if (endDate < startDate) {
    return { disabled: true, reason: 'End date cannot be before start date.' };
  }

  if (startDate > today) {
    return { disabled: true, reason: 'Start date cannot be in the future.' };
  }

  if (endDate > today) {
    return { disabled: true, reason: 'End date cannot be in the future.' };
  }

  if (startDate < new Date(minimumDate)) {
    return {
      disabled: true,
      reason: `Start date cannot be before ${new Date(
        minimumDate
      ).toDateString()}.`,
    };
  }

  return { disabled: false, reason: '' };
};


export const mapRIDTOToAppRI = (RI: [RipenessIndexDTO, RipenessIndexDTO]): SegmentsObject => {
  const [arRI, heRI] = RI.sort((a, b) => a.lang < b.lang ? -1 : 1);

  const getLowerTotal = (heValue: number, arValue: number): number => {
    return Math.min(heValue, arValue);
  };

  const getHigherTotal = (heValue: number, arValue: number): number => {
    return Math.max(heValue, arValue);
  };


  return {
    // STALEMATE
    [Segments.confident]: {
      total: getHigherTotal(heRI.confidentInWinningPercent, arRI.confidentInWinningPercent),
      HE: heRI.confidentInWinningPercent,
      AR: arRI.confidentInWinningPercent
    },
    [Segments.impossibility]: {
      total: getLowerTotal(heRI.skepticPercent, arRI.skepticPercent),
      HE: heRI.skepticPercent,
      AR: arRI.skepticPercent
    },

    // WO
    [Segments.Willingness]: {
      total: getLowerTotal(heRI.forCompromisePercent, arRI.forCompromisePercent),
      HE: heRI.forCompromisePercent,
      AR: arRI.forCompromisePercent
    },
    [Segments.firmness]: {
      total: getHigherTotal(heRI.againstCompromisePercent, arRI.againstCompromisePercent),
      HE: heRI.againstCompromisePercent,
      AR: arRI.againstCompromisePercent
    },

    // HURT
    [Segments.hurt]: {
      total: getLowerTotal(heRI.sufferingPercent, arRI.sufferingPercent),
      HE: heRI.sufferingPercent,
      AR: arRI.sufferingPercent
    },
    [Segments.mhs]: { total: 0, HE: 0, AR: 0 }, // Placeholder - calculated later
    [Segments.wo]: { total: 0, HE: 0, AR: 0 },  // Placeholder - calculated later
    [Segments.ri]: { total: 0, HE: 0, AR: 0 },// Placeholder - calculated later
  };
};

const colorPriority: { [key in RIHexCode]: number } = {
  [RIHexCode.red]: 0,
  [RIHexCode.yellow]: 1,
  [RIHexCode.green]: 2,
};

export const getPriorityColor = (colors: RIHexCode[]): RIHexCode => {
  return colors.reduce((lowestColor, currentColor) =>
    colorPriority[currentColor] < colorPriority[lowestColor] ? currentColor : lowestColor,
    RIHexCode.green);  // Default to green if all are green
};


export const getInnerColors = (segments: SegmentsObject): {
  [Segments.ri]: { ar: RIHexCode, he: RIHexCode };
  [Segments.mhs]: { ar: RIHexCode, he: RIHexCode };
  [Segments.wo]: { ar: RIHexCode, he: RIHexCode };
} => {
  // Get colors for mhs segments (left side)
  const mhsColorsHE = leftSideMHSSegments.map(segment => getColorFromRI(segment, segments[segment].HE));
  const mhsColorsAR = leftSideMHSSegments.map(segment => getColorFromRI(segment, segments[segment].AR));

  const mhsColorHE = getPriorityColor(mhsColorsHE);
  const mhsColorAR = getPriorityColor(mhsColorsAR);

  // Get colors for wo segments (right side)
  const woColorsHE = rightSideWOSegments.map(segment => getColorFromRI(segment, segments[segment].HE));
  const woColorsAR = rightSideWOSegments.map(segment => getColorFromRI(segment, segments[segment].AR));

  const woColorHE = getPriorityColor(woColorsHE);
  const woColorAR = getPriorityColor(woColorsAR);

  // Calculate colors for ri (Ripeness Index) based on lowest color between mhs and wo
  const riColorHE = getPriorityColor([mhsColorHE, woColorHE]);
  const riColorAR = getPriorityColor([mhsColorAR, woColorAR]);

  return {
    [Segments.mhs]: { ar: mhsColorAR, he: mhsColorHE },
    [Segments.wo]: { ar: woColorAR, he: woColorHE },
    [Segments.ri]: { ar: riColorAR, he: riColorHE }
  };
};

export const getFormattedLastTenDaysString = () => {
  const today = new Date();
  const tenDaysAgo = new Date();

  // Subtract 10 days from today
  tenDaysAgo.setDate(today.getDate() - 10);

  // Format date as 'YYYY-MM-DD'
  const formatDate = (date: Date) => date.toISOString().split('T')[0];

  const todayStr = formatDate(today);
  const tenDaysAgoStr = formatDate(tenDaysAgo);

  return `${tenDaysAgoStr} to ${todayStr}`;
};

export const getLastTenDaysQuery = () => {
  const today = new Date().toISOString().split('T')[0];
  const tenDaysAgo = new Date();
  tenDaysAgo.setDate(tenDaysAgo.getDate() - 10);
  const tenDaysAgoString = tenDaysAgo.toISOString().split('T')[0]
  return `startPeriod=${tenDaysAgoString}&endPeriod=${today}`
}