import { FormEvent, useEffect, useState } from 'react';
import useWindowDimensions, {
  useAppDispatch,
  useAppSelector,
} from '../../utils/hooks';
import {
  loadAllData,
  loadCategories,
  selectAllData,
  setAllData,
} from '../../store/dataSlice';
import LineGraph from './Graph/LineGraph';
import {
  filterByDateLangAndCat,
  GraphOptions,
  Interval,
  isSubmitDisabled,
} from '../../utils/functions';
import { minimumDate } from '../../constants';
import {
  setIsModalVisible,
  setLoading,
  setModalText,
} from '../../store/appSlice';
import { selectEvents } from '../../store/dEventsSlice';
import { DEvent } from '../../types/DEvent';
import { RawDateString } from '../../types/utilTypes';
import { UEventName } from '../../types/UEvent';
import { saveUEventToDB } from '../../services/analyticsService';

export default function CustomDashboard() {
  const [startPeriod, setStartPeriod] = useState(minimumDate);
  const [endPeriod, setEndPeriod] = useState(
    new Date().toISOString().split('T')[0]
  );
  const [displayArData, setDisplayArData] = useState(true);
  const [displayHeData, setDisplayHeData] = useState(true);
  const [displayDate, setDisplayDate] = useState('');
  const [interval, setInterval] = useState<Interval>('dayString');
  const [displayedEvents, setDisplayedEvents] = useState<DEvent[]>([]);
  const [parentCategories, setParentCategories] = useState<string[]>([]);
  const [selectedParentCategories, setSelectedParentCategories] = useState<
    string[]
  >([]);
  const [showEvents, setShowEvents] = useState<boolean>(true);

  // options updated on Form Submit to be sent as props to LG component
  const [usedInterval, setUsedInterval] = useState<Interval>('dayString');
  const [usedStartPeriod, setUsedStartPeriod] = useState(minimumDate);
  const [usedEndPeriod, setUsedEndPeriod] = useState(
    new Date().toISOString().split('T')[0]
  );

  const dispatch = useAppDispatch();
  const data = useAppSelector(selectAllData);
  const events = useAppSelector(selectEvents);

  const { height } = useWindowDimensions();
  const { disabled, reason } = isSubmitDisabled(startPeriod, endPeriod);

  const onFormSubmit = (e: FormEvent) => {
    e.preventDefault();
    dispatch(setLoading(true));
    try {
      dispatch(loadAllData())
        .unwrap()
        .then((res) => {
          let options: GraphOptions = {
            startPeriod,
            endPeriod,
            parentCategories: selectedParentCategories,
          };
          const useBothLangs = displayArData && displayHeData;
          if (!useBothLangs) {
            const lang: ('HE' | 'AR')[] = [];
            if (displayArData) lang.push('AR');
            if (displayHeData) lang.push('HE');
            options.lang = lang;
          }

          const newData = filterByDateLangAndCat(res.data, options);
          if (newData.length) {
            dispatch(setAllData(newData));
            setDisplayDate(
              `${new Date(startPeriod).toDateString()} to ${new Date(
                endPeriod
              ).toDateString()}`
            );
          } else {
            throw new Error(
              'No data exists for these filters. Please edit filters and try again'
            );
          }
          setUsedInterval(interval);
          setUsedEndPeriod(endPeriod);
          setUsedStartPeriod(startPeriod);

          const newEvents = events.filter((e) => {
            const eventDate = new Date(e.date);
            const startDate = new Date(startPeriod);
            const endDate = new Date(endPeriod);
            const startMatches = eventDate > startDate;
            const endMatches = eventDate < endDate;
            return startMatches && endMatches;
          });
          setDisplayedEvents(newEvents);
          dispatch(setLoading(false));
        })
        .catch((e) => {
          dispatch(setModalText(e.message));
          dispatch(setIsModalVisible(true));
          dispatch(setLoading(false));
        });

      saveUEventToDB({
        event: {
          date: new Date(),
          eventName: UEventName.filter,
          graphSettings: {
            parentCategories: selectedParentCategories,
            interval: usedInterval,
            // @ts-ignore
            lang: [
              ...(displayArData ? ['AR'] : []),
              ...(displayHeData ? ['HE'] : []),
            ],
            startPeriod: usedStartPeriod,
            endPeriod: usedEndPeriod,
          },
        },
      });
    } catch (e: any) {
      dispatch(setIsModalVisible(true));
      dispatch(setModalText(e));
      dispatch(setLoading(false));
    }
  };

  const handleCheckboxChange = (category: string) => {
    setSelectedParentCategories((prevSelected) => {
      if (prevSelected.includes(category)) {
        return prevSelected.filter((item) => item !== category);
      } else {
        return [...prevSelected, category];
      }
    });
  };

  useEffect(() => {
    setDisplayDate(
      `${new Date(startPeriod).toDateString()} to ${new Date(
        endPeriod
      ).toDateString()}`
    );
    dispatch(loadCategories())
      .unwrap()
      .then(({ data: categories }) => {
        const serverParentCategories = Array.from(
          new Set(categories.map((c) => c.parentCategory))
        );
        setParentCategories(serverParentCategories);
        setSelectedParentCategories(serverParentCategories);
      });
  }, []);

  useEffect(() => {
    dispatch(setLoading(true));
    setDisplayedEvents(events);
    dispatch(loadAllData());
    dispatch(setLoading(false));
  }, [dispatch, events]);

  return (
    <div style={{ margin: 48 }}>
      <h1>Custom Parameters</h1>

      <form
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',
          gap: 10,
          margin: 10,
        }}
        onSubmit={onFormSubmit}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            gap: 12,
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <div>
            <div style={{ color: 'navy' }}>Language</div>
            <div
              style={{
                display: 'flex',
                gap: 12,
                width: 200,
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <div style={{ display: 'flex', gap: 5 }}>
                <input
                  onChange={(e) => setDisplayArData(e.target.checked)}
                  name='ar'
                  type='checkbox'
                  checked={displayArData}
                  disabled={!displayHeData}
                />
                <label
                  style={{ cursor: 'pointer' }}
                  onClick={() => setDisplayArData((prev) => !prev)}
                  htmlFor='ar'
                >
                  Arabic
                </label>
              </div>
              <div style={{ display: 'flex', gap: 5 }}>
                <input
                  disabled={!displayArData}
                  checked={displayHeData}
                  name='he'
                  onChange={(e) => setDisplayHeData(e.target.checked)}
                  type='checkbox'
                />
                <label
                  style={{ cursor: 'pointer' }}
                  onClick={() => setDisplayHeData((prev) => !prev)}
                  htmlFor='he'
                >
                  Hebrew
                </label>
              </div>
            </div>
          </div>
          <div>
            <div style={{ color: 'navy' }}>Aggregate by</div>
            <div
              style={{
                display: 'flex',
                gap: 12,
                width: 200,
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <div style={{ display: 'flex', gap: 5 }}>
                <input
                  onChange={() => setInterval('dayString')}
                  name='interval'
                  id='days'
                  checked={interval === 'dayString'}
                  type='radio'
                />
                <label
                  style={{ cursor: 'pointer' }}
                  onClick={() => setInterval('dayString')}
                  htmlFor='days'
                >
                  Days
                </label>
              </div>
              <div style={{ display: 'flex', gap: 5 }}>
                <input
                  checked={interval === 'weekString'}
                  name='interval'
                  id='weeks'
                  onChange={(e) => setInterval('weekString')}
                  type='radio'
                />
                <label
                  style={{ cursor: 'pointer' }}
                  onClick={() => setInterval('weekString')}
                  htmlFor='weeks'
                >
                  Weeks
                </label>
              </div>
            </div>
          </div>
          <div style={{ display: 'flex', gap: 20 }}>
            <div style={{ display: 'flex', flexDirection: 'column', gap: 12 }}>
              <label htmlFor='startPeriod' style={{ color: 'navy' }}>
                Start Date (MM/DD/YYYY)
              </label>
              <input
                className='px-12 py-2'
                name='startPeriod'
                min={minimumDate}
                value={startPeriod}
                onChange={(e) =>
                  setStartPeriod(e.target.value as RawDateString)
                }
                type='date'
                max={endPeriod}
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', gap: 12 }}>
              <label style={{ color: 'navy' }} htmlFor='endPeriod'>
                End Date (MM/DD/YYYY)
              </label>
              <input
                className='px-12 py-2'
                name='endPeriod'
                min={startPeriod}
                max={new Date().toISOString().split('T')[0]}
                value={endPeriod}
                onChange={(e) => setEndPeriod(e.target.value)}
                type='date'
              />
            </div>
          </div>
        </div>
        <div style={{ width: '50%', alignSelf: 'start' }}>
          <div>Category</div>
          <div
            style={{
              display: 'flex',
              gap: '10px',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
            }}
          >
            {parentCategories.map((category, i) => {
              if (!category) return <></>;
              return (
                <div
                  style={{ display: 'flex', gap: 5 }}
                  key={`${category}-${i}`}
                >
                  <label
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleCheckboxChange(category)}
                    htmlFor={category}
                  >
                    {category}
                  </label>
                  <input
                    name={category}
                    type='checkbox'
                    value={category}
                    checked={selectedParentCategories.includes(category)}
                    onChange={() => handleCheckboxChange(category)}
                  />
                </div>
              );
            })}
          </div>
        </div>
        {disabled ? (
          <div style={{ color: 'red' }}>{reason}</div>
        ) : (
          <div style={{ height: 24 }}></div>
        )}

        <button
          disabled={disabled}
          className='px-12'
          style={{
            opacity: disabled ? '0.3' : '1',
            backgroundColor: 'navy',
            color: 'white',
            padding: '10px 0px',
            width: 150,
          }}
          type='submit'
        >
          UPDATE
        </button>
        <button
          className='px-12'
          style={{
            opacity: disabled ? '0.3' : '1',
            backgroundColor: 'navy',
            color: 'white',
            width: 150,
            padding: '10px 0px',
          }}
          type='button'
          onClick={() => setShowEvents((p) => !p)}
        >
          {showEvents ? 'Hide Events' : 'Show Events'}
        </button>
      </form>
      <LineGraph
        allowDownload
        subtitle={'Date: ' + displayDate}
        height={`${(height * 0.9).toString()}px`}
        data={data}
        events={showEvents ? displayedEvents : []}
        title='Custom Graph'
        graphOptions={{
          parentCategories: selectedParentCategories,
          interval: usedInterval,
          // @ts-ignore
          lang: [
            ...(displayArData ? ['AR'] : []),
            ...(displayHeData ? ['HE'] : []),
          ],
          startPeriod: usedStartPeriod,
          endPeriod: usedEndPeriod,
        }}
      />
    </div>
  );
}
