import { Segments } from "./types/RipenessIndex";
import { RawDateString } from "./types/utilTypes";

export const RIDiagramWidth = 700;
export const center = RIDiagramWidth / 2;
export const outerRadius = RIDiagramWidth * (49 / 100);
export const middleRadius = RIDiagramWidth * (19 / 70);
export const innerRadius = RIDiagramWidth * (1 / 9);

export const outerSegments: Segments[] = [
  Segments.confident,
  Segments.Willingness,
  Segments.firmness,
  Segments.impossibility,
  Segments.hurt,
];

export const innerSegments: Segments[] = [
  Segments.mhs,
  Segments.ri,
  Segments.wo,
];

export const explainerText = {
  [Segments.mhs]:
    ' A mutually hurting stalemate exists when both sides realize that they cannot win the conflict outright, and that continuing the conflict is more harmful than seeking a resolution.',
  [Segments.wo]: 'The perception of a way out involves a belief that there is some viable solution to the conflict that both parties can agree upon.',
  [Segments.confident]: 'Parties are confident in their ability to win the conflict through military conflict or one-sided measures',
  [Segments.Willingness]: 'Parties express a willingness to compromise or flexibility in their positions.',
  [Segments.firmness]: 'Parties express a rigid position against compromising',
  [Segments.hurt]: 'Parties experience a significant and continuous sense of suffering or loss. This is an expression of the harm caused by the Stalemate',
  [Segments.impossibility]: 'Parties  express skepticism in their ability to win the conflict through violent resistance alone',
  [Segments.ri]: 'The optimal conditions for resolving a conflict arise when the involved parties recognize they are stuck in a mutually hurting stalemate and perceive a feasible and non-violent way out,',
};

export const leftSideMHSSegments: Segments[] = [Segments.hurt, Segments.impossibility, Segments.confident,];
export const rightSideWOSegments: Segments[] = [Segments.Willingness, Segments.firmness];

export const minimumDate: RawDateString = new Date(Date.UTC(2023, 8, 10)).toISOString().split('T')[0] as RawDateString;

export enum RIColor {
  red = 'red',
  yellow = 'yellow',
  green = 'green'
}

export enum RIHexCode {
  red = '#ee432a',
  yellow = '#fdb61d',
  green = '#30b04b',
}

export const RIColorToHex: Record<RIColor, RIHexCode> = {
  [RIColor.yellow]: RIHexCode['yellow'],
  [RIColor.red]: RIHexCode['red'],
  [RIColor.green]: RIHexCode['green'],
}

interface RangeObject {
  lower: number;
  higher: number;
}

export type ColorRangeObject = {
  [key in RIColor]: RangeObject
}

export const segmentThresholds: {
  [key in Segments]: ColorRangeObject
} = {
  // STALEMATE
  [Segments.impossibility]: {
    [RIColor.red]: {
      lower: 0,
      higher: 35
    },
    [RIColor.yellow]: {
      lower: 36,
      higher: 55
    },
    [RIColor.green]: {
      lower: 56,
      higher: 100
    }
  },
  [Segments.confident]: {
    [RIColor.red]: {
      lower: 56,
      higher: 100
    },
    [RIColor.yellow]: {
      lower: 36,
      higher: 55
    },
    [RIColor.green]: {
      lower: 0,
      higher: 35
    }
  },

  // WAY OUT
  [Segments.Willingness]: {
    [RIColor.red]: {
      lower: 0,
      higher: 50
    },
    [RIColor.yellow]: {
      lower: 51,
      higher: 65
    },
    [RIColor.green]: {
      lower: 66,
      higher: 100
    }
  },
  [Segments.firmness]: {
    [RIColor.red]: {
      lower: 66,
      higher: 100
    },
    [RIColor.yellow]: {
      lower: 51,
      higher: 65
    },
    [RIColor.green]: {
      lower: 0,
      higher: 50
    }
  },

  // SUFFERING:
  [Segments.hurt]: {
    [RIColor.red]: {
      lower: 0,
      higher: 60
    }
    ,
    [RIColor.yellow]: {
      lower: 61,
      higher: 80
    },
    [RIColor.green]: {
      lower: 81,
      higher: 100
    }
  },

  // INNER SEGMENTS - placeholders that wont be used for color determination
  [Segments.mhs]: {
    [RIColor.red]: {
      lower: 0,
      higher: 60
    }
    ,
    [RIColor.yellow]: {
      lower: 61,
      higher: 80
    },
    [RIColor.green]: {
      lower: 81,
      higher: 100
    }
  },
  [Segments.wo]: {
    [RIColor.red]: {
      lower: 0,
      higher: 60
    }
    ,
    [RIColor.yellow]: {
      lower: 61,
      higher: 80
    },
    [RIColor.green]: {
      lower: 81,
      higher: 100
    }
  },
  [Segments.ri]: {
    [RIColor.red]: {
      lower: 0,
      higher: 60
    }
    ,
    [RIColor.yellow]: {
      lower: 61,
      higher: 80
    },
    [RIColor.green]: {
      lower: 81,
      higher: 100
    }
  }
}

export const MHSCategoriesForDisplay = [
  'Confident in Winning',
  'Skeptical of Winning',
  'Total Suffering'
]