import { User } from '../../types/User';

const UserList = ({users}: {users: User[]}) => {

    return (
        <div>
          <h1>User List</h1>
          <table className="user-table">
            <thead>
              <tr>
                <th>Email</th>
                <th>Organization</th>
                <th>Basic Access</th>
                <th>Design Partner</th>
                <th>Custom Access</th>
                <th>Admin</th>
                <th>Date Created</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user, index) => (
                <tr key={user._id} className={index % 2 === 0 ? 'even-row' : 'odd-row'}>
                  <td>{user.email}</td>
                  <td>{user.organization}</td>
                  <td>{user.access.basic ? 'Yes' : 'No'}</td>
                  <td>{user.access.designPartner ? 'Yes' : 'No'}</td>
                  <td>{user.access.custom.length > 0 ? user.access.custom.join(', ') : 'None'}</td>
                  <td>{user.access.admin ? 'Yes' : 'No'}</td>
                  <td>{new Date(user.dateCreated).toLocaleDateString()}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
};

export default UserList;
